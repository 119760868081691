<template>
  <div>
    <h1 v-if="contentModulesPending">
      Loading...
    </h1>
    <ContentModules v-else :content-modules="contentModules" />
  </div>
</template>

<script setup lang="ts">
import { usePageData } from "~/composables/usePageData"
import { useContentModules } from "~/composables/useContentModules"
import { usePageTracking } from "~/composables/usePageTracking"

// Fetch general page data
const { page, pagePending, pageError } = await usePageData("/")

// Fetch content modules
const { contentModules, contentModulesPending, contentModulesError } = await useContentModules(page.value?.id)
const { data: jobShop } = useNuxtData("jobShopData")

const trackingData = {
  page,
  contentModules,
  jobShop,
}

// Track page
usePageTracking(true, trackingData)

// Use page data so set meta tags during SSR
const generalConfig
  = page.value?.["general-and-seo"].config.general

useServerSeoMeta({
  robots: () =>
    generalConfig?.isSearchable ? "index, follow" : "noindex, nofollow",
  title: () => `${generalConfig?.title}`,
  ogTitle: () => `${generalConfig?.title}`,
  description: () => `${generalConfig?.description}`,
  ogDescription: () => `${generalConfig?.description}`,
  ogImage: () => `${page.value?.sharingImageUrl}`,
})
</script>
